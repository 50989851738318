import { LightningElement, api, wire, track } from "lwc";
import {
  LabelTranslations,
  MultiLabelAdapter,
  t,
  TRANSLATION_MISSING_MESSAGE,
} from "tbme/localization";
import { showToast } from "tds/toaster";
import { getInputValue, isInputRequired } from "./input";
import {
  profileEditForm,
  ProfileEditForm,
  convertPhoneNumberToE164,
} from "./form";
import { PROFILE_EDIT_QUERY } from "./query";
import {
  GetProfileEdit,
  GetProfileEdit_chassis_profile,
} from "./gql/GetProfileEdit";
import { QueryAdapter, QueryAdapterValue } from "tbme/queryAdapter";
import {
  countryOptions,
  stateOptions,
  sortOptions,
  SelectOption,
  verifyCountry,
  verifyState,
} from "@sfdc-www/digital-i18n";
import { updateProfile, UserProfile } from "../../../shared/profileServices";
import { isRenderProfileChassis, chassis } from "tbme/localState";
import { sharedUrls } from "../localState/urls";
import {
  areVirtualRegistrationFieldsEnabled,
  isHideProfileEditSettingsBannerEnabled,
} from "../../../shared/feature";

export default class extends LightningElement {
  @api open: boolean = false;
  cancelButtonDisabled: boolean = false;
  saveButtonDisabled: boolean = false;
  labels: LabelTranslations = undefined!;
  isPublicProfile: boolean | null = null;
  settingsUrl: string | null = null;
  profile?: GetProfileEdit_chassis_profile;
  @track
  form: ProfileEditForm;
  isSelf: boolean = false;
  backgroundImageUrl?: string;

  constructor() {
    super();
    this.form = profileEditForm();
  }

  get areVirtualRegistrationFieldsEnabled() {
    return areVirtualRegistrationFieldsEnabled();
  }

  get countryOptions(): SelectOption[] {
    const verifiedCountries = countryOptions.map((country) => {
      const validatedCountry = verifyCountry(country.value);
      return {
        ...country,
        label:
          country.value === ""
            ? this.labels.fieldCountryCodePlaceholder
            : validatedCountry!,
      };
    });
    return verifiedCountries.sort(sortOptions);
  }

  private get bannerStyle() {
    // The value is coming in as the string 'undefined' if not defined
    if (this.backgroundImageUrl && this.backgroundImageUrl !== "undefined") {
      return `background-image: url(${this.backgroundImageUrl})`;
    }
    return `background-image: url(${sharedUrls.bannerBackgroundImage()})`;
  }

  get stateOptions(): SelectOption[] {
    const countryCode = this.form?.countryCode?.value;
    if (!countryCode) {
      return [];
    }

    const states = stateOptions[countryCode];
    if (!states || states.length === 0) {
      return [];
    }

    const verifiedStates = states.map((state) => {
      const validatedState = verifyState(countryCode, state.value);
      return {
        ...state,
        label:
          state.value === ""
            ? this.labels.fieldStateCodePlaceholder
            : validatedState!,
      };
    });
    return verifiedStates.sort(sortOptions);
  }

  get stateOptionsDisabled(): boolean {
    return !(this.stateOptions && this.stateOptions.length > 0);
  }

  get stateRequired(): boolean {
    return !this.stateOptionsDisabled;
  }

  countryHandler = (e: CustomEvent): void => {
    // State value and state errors need to be cleared out if country changes
    if (this.form.stateCode) {
      this.form.stateCode.value = undefined;
    }
    this.form["stateCode"].errorMessage = "";

    this.handleFieldChange(e);
  };

  // TODO: Grab this from the 'chassis' context
  get roleOptions(): { label: string; value: string }[] {
    const options = this.labels.roleOptions as any;
    return [
      {
        label: options.developer,
        value: "Developer",
      },
      { label: options.administrator, value: "Administrator" },
      { label: options.architect, value: "Architect" },
      {
        label: options.salesRep,
        value: "Sales Representative / Manager",
      },
      { label: options.marketer, value: "Marketer" },
      {
        label: options.supervisor,
        value: "Service Agent / Supervisor",
      },
      { label: options.dataAnalyst, value: "Data Analyst" },
      { label: options.consultant, value: "Consultant" },
      {
        label: options.businessOperationsManager,
        value: "Business Operations Manager",
      },
      { label: options.designer, value: "Designer" },
      { label: options.educator, value: "Educator" },
      { label: options.student, value: "Student" },
      { label: options.executive, value: "Executive" },
      { label: options.productManager, value: "Product Manager" },
    ];
  }

  // TODO: Grab this from the 'chassis' context
  get relationshipToSalesforceOptions(): { value: string; label: string }[] {
    const options = this.labels.relationshipOptions as any;
    return [
      { label: options.customer, value: "Customer" },
      { label: options.partner, value: "Partner" },
      { label: options.nonCustomerProspect, value: "Non-Customer / Prospect" },
      { label: options.salesforceEmployee, value: "Salesforce Employee" },
    ];
  }

  // TODO: Grab this from the 'chassis' context
  get pronounOptions(): { value: string; label: string }[] {
    const options = this.labels.pronounOptions as any;
    return [
      { label: options.heHimHis, value: "he/him/his" },
      { label: options.otherAskMe, value: "other/ask me" },
      { label: options.sheHerHers, value: "she/her/hers" },
      { label: options.theyThemTheirs, value: "they/them/theirs" },
    ];
  }

  // TODO: Grab this from the 'chassis' context
  get companySizeOptions(): { value: string; label: string }[] {
    const options = this.labels.companySizeOptions as any;
    return [
      { label: options.oneToTwenty, value: "1-20 employees" },
      { label: options.twentyOneToOneHundred, value: "21-100 employees" },
      { label: options.oneHundredToFiveHundred, value: "101-500 employees" },
      {
        label: options.fiveHundredToThreeThousand,
        value: "501-3500 employees",
      },
      { label: options.threeThousandPlus, value: "3501+ employees" },
    ];
  }

  private handleFieldChange(event: CustomEvent) {
    const element = event.target as HTMLElement;
    const identifier = element.dataset.id;
    const value = event.detail;

    if (identifier) {
      this.form[identifier].value = value.replace(/<[^>]*>/g, "").trim();
    }
  }

  @wire(MultiLabelAdapter, {
    labels: [
      "profileEditModal.title",
      "profileEditModal.sectionMe",
      "profileEditModal.fieldGivenName",
      "profileEditModal.fieldFamilyName",
      "profileEditModal.fieldTitle",
      "profileEditModal.fieldRole",
      "profileEditModal.fieldPronoun",
      "profileEditModal.fieldRelationshipToSalesforce",
      "profileEditModal.fieldBio",
      "profileEditModal.fieldSlug",
      "profileEditModal.sectionLocation",
      "profileEditModal.fieldCountryCode",
      "profileEditModal.fieldCountryCodePlaceholder",
      "profileEditModal.fieldStateCode",
      "profileEditModal.fieldStateCodePlaceholder",
      "profileEditModal.sectionMyCompany",
      "profileEditModal.fieldCompanyName",
      "profileEditModal.fieldCompanySize",
      "profileEditModal.fieldCompanyWebsite",
      "profileEditModal.sectionSocials",
      "profileEditModal.fieldFacebook",
      "profileEditModal.fieldLinkedIn",
      "profileEditModal.fieldTwitter",
      "profileEditModal.fieldWebsite",
      "profileEditModal.validationRequired",
      "profileEditModal.buttonSave",
      "profileEditModal.buttonCancel",
      "profileEditModal.toastUpdateSuccess",
      "profileEditModal.toastFieldInvalid",
      "profileEditModal.toastUpdateError",
      "profileEditModal.roleOptions",
      "profileEditModal.pronounOptions",
      "profileEditModal.relationshipOptions",
      "profileEditModal.companySizeOptions",
      "profileEditModal.fieldWorkEmail",
      "profileEditModal.fieldWorkPhone",
      "profileEditModal.fieldMobilePhone",
      "profileEditModal.phoneFieldFormat",
    ],
  })
  private handleLabels(labels: { profileEditModal: LabelTranslations }) {
    this.labels = {
      ...labels.profileEditModal,
    };
  }

  private get settingsNoticeClass() {
    if (this.isPublicProfile !== null) {
      return this.isPublicProfile
        ? "settings-notice-public"
        : "settings-notice-private";
    }
  }

  private get settingsNotice() {
    if (isHideProfileEditSettingsBannerEnabled()) {
      return null;
    }

    if (this.isPublicProfile !== null && this.settingsUrl !== null) {
      let settingsNoticeLabel = this.isPublicProfile
        ? "profileEditModal.settingsNoticePublicProfile"
        : "profileEditModal.settingsNoticePrivateProfile";
      return t(settingsNoticeLabel, { settingsUrl: this.settingsUrl });
    }
  }

  @wire(QueryAdapter, {
    query: PROFILE_EDIT_QUERY,
  })
  private handleResult(result: QueryAdapterValue<GetProfileEdit>) {
    const { data } = result;

    //TODO: Handle errors. Handle loading(?)
    if (data?.chassis?.profile) {
      this.backgroundImageUrl =
        data.chassis.profile?.backgroundImageUrl ?? undefined;
      this.profile = data?.chassis?.profile;
      this.assignToForm(this.profile);

      this.isPublicProfile = this.profile.isPublicProfile;

      // tds-textarea does not expose an api property to set its
      // value. As a workaround, we'll instead need to select the
      // element and manually set the value.
      const element = this.getTextArea("bio");
      if (element) {
        element.value = this.form.bio.value || "";
      }
    }

    if (data?.siteInfo?.urls) {
      this.settingsUrl = data.siteInfo.urls.settings;
    }
  }

  assignToForm(profile: GetProfileEdit_chassis_profile) {
    for (let obj in this.form) {
      this.form[obj].errorMessage = undefined;
    }
    this.form.givenName.value = profile.firstName;
    this.form.familyName.value = profile.lastName;
    this.form.title.value = profile.title;
    this.form.role.value = profile.role;
    this.form.pronoun.value = profile.pronoun;
    this.form.relationshipToSalesforce.value = profile.relationshipToSalesforce;
    this.form.bio.value = profile.bio;
    this.form.slug.value = profile.username;
    this.form.countryCode.value = profile.address?.country;
    this.form.stateCode.value = profile.address?.state;
    this.form.companyName.value = profile.company?.name;
    // Needed for profiles that incorrectly had 3500+ employees instead of 3501+ employees
    this.form.companySize.value = profile.company?.size?.replace(
      "3500+ employees",
      "3501+ employees"
    );
    this.form.companySize.value = profile.company?.size?.replace(
      "1-20 Employees",
      "1-20 employees"
    );
    this.form.companyWebsite.value = profile.company?.website;
    this.form.facebook.value = profile.facebookHandle;
    this.form.linkedIn.value = profile.linkedinHandle;
    this.form.twitter.value = profile.twitterHandle;
    this.form.website.value = profile.websiteUrl;
    if (this.areVirtualRegistrationFieldsEnabled) {
      this.form.workEmail.value = profile.workEmail;
      this.form.mobilePhone.value = profile.phone?.mobile;
      this.form.workPhone.value = profile.phone?.work;
    }
  }

  private closeModal() {
    this.dispatchEvent(new CustomEvent("profileeditrequestclose"));
  }

  private getInput(dataId: string): HTMLInputElement | null | undefined {
    return this.template
      .querySelector(`[data-id="${dataId}"]`)
      ?.shadowRoot?.querySelector("input");
  }

  private getTextArea(dataId: string): HTMLTextAreaElement | null | undefined {
    return this.template
      .querySelector(`[data-id="${dataId}"]`)
      ?.shadowRoot?.querySelector("textarea");
  }

  private getSelect(dataId: string): HTMLSelectElement | null | undefined {
    return this.template
      .querySelector(`[data-id="${dataId}"]`)
      ?.shadowRoot?.querySelector("select");
  }

  // returns 'true' if the input value is valid
  private validateInput(dataId: string): boolean {
    let input = this.getInput(dataId) || this.getSelect(dataId);
    let inputValue = getInputValue(input);
    this.form[dataId].errorMessage = "";

    if (isInputRequired(input) && !inputValue) {
      this.form[dataId].errorMessage = this.labels.validationRequired;
      return false;
    }

    // validate input only is a value is present
    if (inputValue && this.form[dataId].validationRules?.length) {
      return this.form[dataId].validationRules!.every((rule) => {
        // Input value must conform with the RegExp and validation functions (if present)
        if (rule.regex && !rule.regex.test(inputValue)) {
          this.form[dataId].errorMessage = t(rule.errorLabel);
          return false;
        }
        if (rule.fn && !rule.fn(inputValue)) {
          this.form[dataId].errorMessage = t(rule.errorLabel);
          return false;
        }
        return true;
      });
    }
    return true;
  }

  private validate(): boolean {
    let isFormValid: boolean = true;
    for (const fieldName in this.form) {
      let isFieldValid = this.validateInput(fieldName);
      isFormValid = isFormValid && isFieldValid;
    }

    return isFormValid;
  }

  private handleRequestClose() {
    if (this.profile) {
      this.assignToForm(this.profile);
    }
    this.closeModal();
  }

  private assemblePayload(): Partial<UserProfile> {
    const address = {
      country: this.form.countryCode.value || "",
      state: this.form.stateCode.value || "",
    };

    const company = {
      name: this.form.companyName.value || "",
      size: this.form.companySize.value || "",
      website: this.form.companyWebsite.value || "",
    };

    let virtualRegistrationFields = {};
    if (this.areVirtualRegistrationFieldsEnabled) {
      this.form.workPhone.value = convertPhoneNumberToE164(
        this.form.workPhone.value ?? ""
      );
      this.form.mobilePhone.value = convertPhoneNumberToE164(
        this.form.mobilePhone.value ?? ""
      );
      virtualRegistrationFields = {
        phone: {
          work: this.form.workPhone.value,
          mobile: this.form.mobilePhone.value,
        },
        workEmail: this.form.workEmail.value || "",
      };
    }

    return {
      address,
      company,
      firstName: this.form.givenName.value || "",
      lastName: this.form.familyName.value || "",
      title: this.form.title.value || "",
      role: this.form.role.value || "",
      pronoun: this.form.pronoun.value || "",
      relationshipToSalesforce: this.form.relationshipToSalesforce.value || "",
      bio: this.form.bio.value || "",
      username: this.form.slug.value || "",
      twitterHandle: this.form.twitter.value || "",
      facebookHandle: this.form.facebook.value || "",
      linkedinHandle: this.form.linkedIn.value || "",
      websiteUrl: this.form.website.value || "",
      ...virtualRegistrationFields,
    };
  }

  private async handleRequestSave() {
    this.cancelButtonDisabled = true;
    this.saveButtonDisabled = true;

    const valid = this.validate();
    if (valid) {
      const payload = this.assemblePayload();

      try {
        const response = await updateProfile(payload);
        this.updateChassisProfile(response);

        showToast({
          message: this.labels.toastUpdateSuccess,
          variant: "success",
        });

        this.closeModal();
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : "";
        showToast({
          message: this.translateProfileUpdateError(errorMessage),
          variant: "error",
        });
      }
    } else {
      showToast({
        message: this.labels.toastFieldInvalid,
        variant: "error",
      });
    }

    this.cancelButtonDisabled = false;
    this.saveButtonDisabled = false;
  }

  // For a small subset of cases attempt to translate the error coming back from the profile service
  // Eventually this should be replaced by error codes
  private translateProfileUpdateError(responseText: string) {
    if (
      responseText.includes("Username is not available") ||
      responseText.includes("Username is unavailable")
    ) {
      return t("profileEditModal.usernameUnavailableError");
    }

    return t("profileEditModal.toastUpdateError");
  }

  updateChassisProfile(value: Partial<UserProfile>) {
    if (!isRenderProfileChassis()) {
      return;
    }

    const profile = Object.assign({}, chassis()!.profile, value) as UserProfile;

    chassis({
      ...chassis()!,
      profile,
    });
  }
}
